<template>
  <div>
  <b-table
      class="medic__table"
     :fields="fields" 
     :items="items" 
     :sort-by.sync="sortBy"
     :sort-desc.sync="sortDesc"
     >
     <template #cell(driver)="data">
        <span class="driver__name" type='submit' @click="driverPage(data.item.id)">
          {{ data.item.name}} 
        </span>
    </template>

    <template #cell(driverPassNum)="data">
        <span class="table__items">
          {{ data.item.documents ? data.item.documents.doc_number : data.item.doc_number }} 
        </span>
    </template>

    <template #cell(bornDate)="data">
        <span class="table__items">
          {{ data.item.date? data.item.date : null }} 
        </span>
    </template>

    <template #cell(city)="data">
        <span class="table__items driver__city  ">
          {{ data.item.city_name }} 
        </span>
    </template>

    <template #cell(phone)="data">
        <span class="table__items">
         +7 {{ data.item.phone }} 
        </span>
    </template>

     <template #cell(work_status)="data">
            <span v-if="data.item.work_status == 'moving'" style="background: #E0E9F9; padding: 5px 10px; border-radius: 3px; color:#3B6FD4;font-weight: 500;font-size: 12px;line-height: 14px;">
                В пути
            </span>
            <span v-if="data.item.work_status == 'free'" style="background:#DCFFE4; padding: 5px 10px; border-radius: 3px; color: #28A745; font-weight: 500;font-size: 12px;line-height: 14px;">
                Свободен
            </span>
    </template>
  </b-table>
  </div>
</template>

<script>
   export default {
      props: {
        items : {
          type : Array,
          default : null
        }
      },
      data() {
        return {
            sortBy: 'age',
            sortDesc: true,
            fields :[
                { 
                    key: 'driver',
                    label: 'ФИО водителя',
                    sortable: true,
                    class: "medic__table",
                },
                { 
                    key: 'driverPassNum',
                    label: 'Водительское уд.',
                    sortable: true,
                    class: "medic__table",
                },
                {
                     key: 'iin',
                     label: 'ИИН',
                     sortable: true,
                     class: "medic__table",
                },
                { 
                    key: 'bornDate',
                    label: 'Дата рождения',
                    sortable: true,
                    class: "medic__table",
                },
                { 
                    key: 'city',
                    label: 'Город',
                    sortable: true,
                    class: "medic__table",
                },
                {
                     key: 'phone',
                     label: 'Контактный номер',
                     sortable: true,
                     class: "medic__table",
                },
                {
                     key: 'work_status',
                     label: 'Статус',
                     sortable: true,
                     class: "medic__table",
                }   
            ],
        }
      },
      methods: {
       driverPage(userId){         
          this.$router.push('drivers/' + userId)
        }
      },
   }
</script>

<style scoped>
.header {
  padding: 15px 30px;
  background: #fff;
  height: 70px;
}


.medic-header{
    height: 70px;
    width: 100%;
    background: white;
    border: solid 2px lightgray;
    text-align: center;
}
.header__logo {
  height: 40px;
  cursor: pointer;
}
.header__menu {
  padding-top: 20px;
}

.header__menu-item {
  border-bottom: 1px solid #00000000;
  cursor: pointer;
  margin-right: 20px;
}

.header__right-user {
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #e0e9fa;
  cursor: pointer;
}

.header__right-icon {
  fill: #acacac;
  cursor: pointer;
}
.medic__table {
    margin: auto;
    margin-top: 20px;
    width: 80%;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
}

.driver__name {
  font-weight: 500;
}

.table__items{
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.driver__city {
  padding: 5px 10px;
  background: #F2F2F8;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

</style>